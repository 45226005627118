<template>
  <div>
    <v-alert
      v-if="!isDisabledSave && !isHideButton"
      outlined
      type="warning"
      prominent
      border="left"
      class="mt-4"
    >
      Silakan pilih SIMPAN jika Anda belum yakin dengan data yang Anda masukan. Apabila semua data yang diperlukan sudah sesuai, silakan pilih KIRIM.
    </v-alert>
    <v-alert
      v-if="isShowAlert"
      dismissible
      type="error"
      class="mt-4"
    >
      {{ alertMsg }}
    </v-alert>
    <validation-observer v-slot="{ invalid }" ref="form">
      <v-form id="form-file-completion" class="file-completion mt-4" @submit.prevent="submitForm">
        <template v-for="(itm, index) in getFileCompletionData">
          <v-row no-gutters :key="`fileCompletion${index}`" class="general-form">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="general-form__col"
              v-for="(item, idx) in separateColoum(itm.list)"
              :key="`fc${idx}`"
            >
              <template
                v-for="form in item.list"
              >
                <div
                  v-if="form.fields === 'article_paper_url'"
                  :key="`guide-${form.title}`"
                  class="my-4"
                >
                  <p class="ile-completion-body mb-2 mt-4">Berikut adalah panduan dalam menulis esai.</p>
                  <v-btn 
                    color="primary"
                    :min-width="160"
                    large
                    @click="downloadGuidance()"
                  >
                    UNDUH
                  </v-btn>
                </div>
                <registration-input 
                  :key="form.title" 
                  :form="form"
                  @handleInput="handleInput"
                />
              </template>
            </v-col>
          </v-row>
        </template>
        <v-divider class="my-4"/>
        <v-row no-gutters v-for="(itm, index) in getTranscriptfile" :key="`edu${index}`" class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
            v-for="(item, idx) in itm.list"
            :key="`sk${idx}`"
          >
            <registration-input 
              :key="item.title" 
              :form="item"
              @handleInput="handleInput"
            />
          </v-col>
        </v-row>
        <template v-if="!isHideButton">
          <v-btn outlined :min-width="120" class="mr-2" :key="`eduBtn1`" @click="handleMultiForm('remove', getFirstTranscriptfile)" :disabled="getDisabledRemove">
            HAPUS
          </v-btn>
          <v-btn color="primary" :min-width="120" :key="`eduhBtn2`" @click="handleMultiForm('add', getFirstTranscriptfile)">
            TAMBAH
          </v-btn>
        </template>
        <div v-if="!isHideButton" class="d-flex justify-end">
          <v-btn 
            v-if="!isDisabledSave"
            color="primary"
            :min-width="120"
            outlined
            class="mr-2"
            @click="handleOnSubmit(true)"
            large
          >
            SIMPAN
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-file-completion"
            large
            :disabled="invalid"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, {getConfig} from '@/utils/request'

// Constants
import {
  REGISTRATION_DETAIL
} from '@/constants/pages'
import {
  API_FORM_COMPLETENESS,
  API_DASH_COMPLETENESS_FORM
} from '@/constants/apis'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '../common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
  export default {
    data () {
      return {
        activeStep: 1,
        agreementCheckbox: false,
        isShowAlert: false,
        alertMsg: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    components: {
      ValidationObserver,
      RegistrationInput,
      DialogConfirm,
      DialogInfo
    },
    props: {
      formId: {
        type: String,
        required: true
      },
      prodi: {
        type: String,
        required: true
      }
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      isStudent () {
        const config = getConfig()
        return config.role === 'mahasiswa'
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      getFileCompletionData () {
        const fileCompletionData = get(this.$store.state, 'formFileCompletion.fileCompletionData', [])
        return this.getData(fileCompletionData)
      },
      getTranscriptfile () {
        const fileCompletionData = get(this.$store.state, 'formFileCompletion.fileCompletionEdu', [])
        return this.getData(fileCompletionData)
      },
      getFirstTranscriptfile () {
        const fileCompletionData = get(this.$store.state, 'formFileCompletion.fileCompletionEdu', [])
        return fileCompletionData[0]
      },
      getDisabledRemove () {
        const fileCompletionData = get(this.$store.state, 'formFileCompletion.fileCompletionEdu', [])
        return fileCompletionData.length <= 1
      },
      isHideButton () {
        return (this.registrationStatus !== INIT && this.isStudent) || ([INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1 && this.isDashboard)
      },
      isDisabledSave () {
        const isFileCompletionForm = get(this.$store.state,'completionForm.is_complete_file', false)
        return !this.isStudent || isFileCompletionForm
      },
    },
    created () {
      this.$store.dispatch('getFileCompletionData', this.prodi)
    },
    mounted () {
      extend('required', required)
      const data = this.$store.state.formFileCompletion
      setTimeout(() => {
        const filledData = data.fileCompletionData.filter(itm => itm.path && itm.path !== '')
        if (!this.isDisabledSave && filledData.length > 0) {
          this.$refs.form.validate().then(success => {
            if(!success) {
              this.alertMsg = 'Silakan periksa kembali kelengkapan data.'
              this.isShowAlert = true
            }
          })
        }
      }, 100)
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
          }
        })
        return data
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, 10)
        }]
      },
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      handleOnSubmit (isDraft = false) {
        loadscreen.show()
        const academicYearList = this.isDashboard ? this.$store.state.dashboard.dashAcademicYear : this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        const formFile = this.$store.state.formFileCompletion
        let params = {}
        formFile.fileCompletionData.map(item => {
          if (item.path) {
            params[item.fields] = item.path
          }
        })
        const academic_transcript_url = []
        formFile.fileCompletionEdu.map((item, idx) => {
          if (item.path) {
            academic_transcript_url.push({
              attr: `academic_transcript${idx+1}`,
              url: item.path
            })
          }
        })
        params.academic_transcript_url = academic_transcript_url
        params.entry_form_id = this.formId
        params.academic_year_id = academicYearId

        let apiUrl = API_FORM_COMPLETENESS
        if (this.isDashboard) {
          apiUrl = API_DASH_COMPLETENESS_FORM
        }
        
        if (isDraft) {
          params.is_save_to_draft = true
        }
        request(apiUrl, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      handleBack () {
        this.$emit('handleBack', true)
      },
      handleInput (form) {
        // console.log(form)
      },
      downloadGuidance () {
        const url = get(this.$store.state, 'completeness_data_form.guide_article_url', '')
        window.open(url, '_blank')
      },
      handleMultiForm (type, itm) {
        if (type === 'add') {
          this.$store.dispatch('addEduFile', itm)
        } else {
          this.$store.dispatch('removeEduFile', itm)
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.file-completion {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
  &-body {
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>