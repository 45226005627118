import { render, staticRenderFns } from "./formFinancialSupport.vue?vue&type=template&id=2c6b3df6&scoped=true&"
import script from "./formFinancialSupport.vue?vue&type=script&lang=js&"
export * from "./formFinancialSupport.vue?vue&type=script&lang=js&"
import style0 from "./formFinancialSupport.vue?vue&type=style&index=0&id=2c6b3df6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6b3df6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VForm,VRow})
