<template>
  <div class="wrapper-form-sheet">
    <v-alert
      v-if="!isDisabledSave && !isHideButton"
      outlined
      type="warning"
      prominent
      border="left"
      class="mt-4"
    >
      Silakan pilih SIMPAN jika Anda belum yakin dengan data yang Anda masukan. Apabila semua data yang diperlukan sudah sesuai, silakan pilih KIRIM.
    </v-alert>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer v-slot="{ invalid }">
      <v-form id="form-sheet" @submit.prevent="submitForm">
        <validation-provider
          :name="form.title"
          rules="required"
        >
          <p class="title-14 mb-2 mt-4">{{ form.title }}</p>
          <ckeditor v-model="form.value" :editor="editor" @ready="onReady" :disabled="isHideButton"></ckeditor>
        </validation-provider>
        <div v-if="!isHideButton" class="d-flex justify-end mt-4">
          <v-btn 
            v-if="!isDisabledSave"
            color="primary"
            :min-width="120"
            outlined
            class="mr-2"
            @click="handleSubmit(true)"
            large
          >
            SIMPAN
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-sheet"
            large
            :disabled="invalid || !form.value"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitSheet" :info="info" :action="handleSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { get } from 'dot-prop'
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, { getConfig } from '@/utils/request'

// Constants
import {
  REGISTRATION_DETAIL
} from '@/constants/pages'
import {
  API_FORM_REPENTANCE_STORY,
  API_FORM_CALLING_FAITH_STORY,
  API_FORM_MOTIVATION_STORY,
  API_DASH_REPENTANCE_FORM,
  API_DASH_CALLING_FAITH,
  API_DASH_MOTIVATION_STORY
} from '@/constants/apis'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'

import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

  export default {
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
        editor: DecoupledEditor
      }
    },
    props: {
      form: {
        type: Object,
        required
      },
      formId: {
        type: String,
        required: true
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogConfirm,
      DialogInfo,
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      isStudent () {
        const config = getConfig()
        return config.role === 'mahasiswa'
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      isHideButton () {
        return (this.registrationStatus !== INIT && this.isStudent) || ([INIT, REJECTED, ACCEPTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1 && this.isDashboard)
      },
      isDisabledSave () {
        const type = this.$route.query.tipe || ''
        const isCompleteForm = get(this.$store.state,`completionForm.${type}`, false)
        return !this.isStudent || isCompleteForm
      },
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      onReady(editor) {
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          )
      },
      submitForm () {
        this.$refs.dialogConfirmSubmitSheet && this.$refs.dialogConfirmSubmitSheet.show()
      },
      handleSubmit (isDraft = false) {
        loadscreen.show()
        const academicYearList = this.isDashboard ? this.$store.state.dashboard.dashAcademicYear : this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        const params = {
          academic_year_id: academicYearId,
          entry_form_id: this.formId
        }
        let api = ''
        if (this.form.parent === 'repentance_story') {
          api = this.isDashboard ? API_DASH_REPENTANCE_FORM : API_FORM_REPENTANCE_STORY
          params.repentance_story = this.form.value
        } else if (this.form.parent === 'calling_faith_story') {
          api = this.isDashboard ? API_DASH_CALLING_FAITH : API_FORM_CALLING_FAITH_STORY
          params.calling_faith_story = this.form.value
        } else {
          api = this.isDashboard ? API_DASH_MOTIVATION_STORY : API_FORM_MOTIVATION_STORY
          params.motivation_story = this.form.value
        }

        if (isDraft) {
          params.is_save_to_draft = true
        }

        request(api, params).then(res => {
          loadscreen.hide()
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
      },
      handleBack () {
        this.$emit('handleBack', true)
      },
      handleInput (e) {
        this.form.value = e
      }
    }
  }
</script>

<style lang="scss" scoped>
.wrapper-form-sheet {
  &__value {
    min-height: 300px;
  }
  ::v-deep .ck-editor__editable_inline {
    min-height: 400px;
    border: 1px solid grey;
  }
}
</style>