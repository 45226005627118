<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <v-alert
        outlined
        type="warning"
        prominent
        border="left"
        class="mt-4"
      >
        Surat Rekomendasi akan dikirimkan sesuai dengan e-mail yang Anda masukan ketika Anda menekan tombol KIRIM. Isilah keterangan pengisi formulir rekomendasi dengan benar karena hanya dapat dilakukan satu kali. Apabila Anda belum yakin dengan data yang Anda masukan, silakan SIMPAN terlebih dahulu.
      </v-alert>
      <v-alert
        dismissible
        type="error"
        v-model="isShowAlert"
      >{{ alertMsg }}</v-alert>
      <v-form id="form-recommendation" class="recommendation" @submit.prevent="submitForm">
        <template v-for="(itm, index) in getRecommendationData">
          <p class="recommendation-body mb-2 mt-4" :key="`recomtitle${index}`">{{itm.head}}</p>
          <v-row no-gutters :key="`recom${index}`" class="general-form">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="general-form__col"
              v-for="(item, idx) in separateColoum(itm.list)"
              :key="`rc${idx}`"
            >
              <template
                v-for="form in item.list"
              >
                <registration-input 
                  :key="form.title" 
                  :form="form"
                  :disabled="isHideButton"
                  @handleInput="handleInput"
                />
              </template>
            </v-col>
          </v-row>
        </template>
        <div v-if="!isHideButton" class="d-flex justify-end">
          <v-btn 
            v-if="!isDisabledSave"
            color="primary"
            :min-width="120"
            outlined
            class="mr-2"
            @click="handleOnSubmit(true)"
            large
          >
            SIMPAN
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-recommendation"
            large
            :disabled="invalid"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, { getConfig } from '@/utils/request'

// Constants
import {
  REGISTRATION_DETAIL
} from '@/constants/pages'
import {
  API_FORM_RECOMMENDATION,
  API_DASH_RECOMMENDATION_FORM
} from '@/constants/apis'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '../common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
  export default {
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    components: {
      ValidationObserver,
      RegistrationInput,
      DialogConfirm,
      DialogInfo
    },
    props: {
      prodi: {
        type: String,
        required: true
      },
      formId: {
        type: String,
        required: true
      }
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      isStudent () {
        const config = getConfig()
        return config.role === 'mahasiswa'
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      getRecommendationData () {
        const recommendationData = get(this.$store.state, 'formRecommendation.recommendData', [])
        return this.getData(recommendationData)
      },
      isRecommendationForm () {
        return get(this.$store.state, 'completionForm.is_recommendation_form', false)
      },
      isHideButton () {
        return ((this.registrationStatus !== INIT || this.isRecommendationForm) && this.isStudent) || ([INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1 && this.isDashboard)
      },
      isDisabledSave () {
        const isRecommendationForm = get(this.$store.state,'completionForm.is_recommendation_form', false)
        return !this.isStudent || isRecommendationForm
      },
    },
    created () {
      this.$store.dispatch('getRecommendationData', this.prodi)
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
            data[itm.group-1].head = data[itm.group-1].list[0].head
          }
        })
        return data
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, 10)
        }]
      },
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      handleOnSubmit (isDraft = false) {
        loadscreen.show()
        const academicYearList = this.isDashboard ? this.$store.state.dashboard.dashAcademicYear : this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        const formRecommendation = this.$store.state.formRecommendation
        let params = {}
        formRecommendation.recommendData.map(itm => {
          params[itm.fields] = itm.value
        })
        params.entry_form_id = this.formId
        params.academic_year_id = academicYearId
        let apiUrl = API_FORM_RECOMMENDATION
        if (this.isDashboard) {
          apiUrl = API_DASH_RECOMMENDATION_FORM
        }
        if (isDraft) {
          params.is_save_to_draft = true
        }
        request(apiUrl, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      handleBack () {
        this.$emit('handleBack', true)
      },
      handleInput (form) {
        // console.log(form)
      }
    }
  }
</script>

<style lang="scss" scoped>
.recommendation {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
  &-body {
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>