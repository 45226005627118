<template>
  <div>
    <v-alert
      v-if="!isDisabledSave && !isHideButton"
      outlined
      type="warning"
      prominent
      border="left"
      class="mt-4"
    >
      Silakan pilih SIMPAN jika Anda belum yakin dengan data yang Anda masukan. Apabila semua data yang diperlukan sudah sesuai, silakan pilih KIRIM.
    </v-alert>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer v-slot="{ invalid }" ref="form">
      <v-form id="form-health" class="wrapper-form-health" @submit.prevent="submitForm">
        <p class="wrapper-form-health-body mb-2 mt-4">Silakan unggah surat keterangan kesehatan dari dokter dalam bentuk file pdf.</p>
        <!-- <v-btn 
          color="primary"
          :min-width="160"
          large
          :disabled="isDisabledDownload"
          @click="downloadFile"
        >
          UNDUH
        </v-btn> -->
        <v-row no-gutters v-for="(itm, index) in getHealthFormData" :key="`healthF${index}`" class="general-form mt-4">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
            v-for="(item, idx) in separateColoum(itm.list)"
            :key="`hf${idx}`"
          >
            <template
              v-for="form in item.list"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
          </v-col>
        </v-row>
        <!-- <p class="wrapper-form-health-body mt-4"> Anda diharapkan melakukan tes darah rutin dan X-ray (foto Thorax)</p>
        <p class="wrapper-form-health-body mb-2 mt-2">Silakan unggah hasil tes darah, foto thorax, dan berkas lainnya</p>
        <v-row no-gutters v-for="(itm, index) in getHealthData" :key="`health${index}`" class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
            v-for="(item, idx) in separateColoum(itm.list)"
            :key="`sk${idx}`"
          >
            <template
              v-for="form in item.list"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
          </v-col>
          <template v-if="itm.list[0].isMultiData && !isHideButton">
            <v-btn outlined :min-width="120" class="mr-2" :key="`healthBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
              HAPUS
            </v-btn>
            <v-btn color="primary" :min-width="120" :key="`heakthBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
              TAMBAH
            </v-btn>
          </template>
        </v-row> -->
        <div v-if="!isHideButton" class="d-flex justify-end">
          <v-btn 
            v-if="!isDisabledSave"
            color="primary"
            :min-width="120"
            outlined
            class="mr-2"
            @click="handleOnSubmit(true)"
            large
          >
            SIMPAN
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-health"
            large
            :disabled="invalid"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, { getConfig } from '@/utils/request'

// Constants
import {
  REGISTRATION_DETAIL
} from '@/constants/pages'
import {
  API_GET_HEALTH,
  API_FORM_HEALTH,
  API_DASH_HEALTH_FORM
} from '@/constants/apis'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '../common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
  export default {
    data () {
      return {
        healthForm: [],
        healthUrl: '',
        isDisabledDownload: false,
        isShowAlert: false,
        alertMsg: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    components: {
      ValidationObserver,
      RegistrationInput,
      DialogConfirm,
      DialogInfo
    },
    props: {
      formId: {
        type: String,
        required: true
      }
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      isStudent () {
        const config = getConfig()
        return config.role === 'mahasiswa'
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      getHealthData () {
        const healthData = get(this.$store.state, 'formHealth.health_related_file_url', [])
        return this.getData(healthData)
      },
      getHealthFormData () {
        const healthFormData = get(this.$store.state, 'formHealth.health_form_url', [])
        return this.getData(healthFormData)
      },
      isHideButton () {
        return (this.registrationStatus !== INIT && this.isStudent) || ([INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1 && this.isDashboard)
      },
      isDisabledSave () {
        const isHealthForm = get(this.$store.state,'completionForm.is_health_form', false)
        return !this.isStudent || isHealthForm
      },
    },
    created () {
      request(API_GET_HEALTH, {}, {methods: 'GET'}).then(res => {
        if (res.success) {
          this.healthUrl = get(res, 'health_file_url', '')
        } else {
          this.alertMsg = get(res, 'resultInfo.resultMsg', '')
          this.isShowAlert = true
          this.isDisabledDownload = true
        }
      })
      this.$store.dispatch('getHealthForm')
    },
    mounted () {
      extend('required', required)
      extend('maxSized', {
        validate: (value) => {
          if (value && value.size <= 2000000) {
            return true
          }
          return 'File terlalu besar, max 2Mb.'
        }
      })
      setTimeout(() => {
        const formHealth = this.$store.state.formHealth
        const filledData1 = formHealth.health_form_url.filter(itm => itm.path && itm.path !== '')
        const filledData2 = formHealth.health_related_file_url.filter(itm => itm.path && itm.path !== '')

        if (!this.isDisabledSave && (filledData1.length > 0 || filledData2.length > 0)) {
          this.$refs.form.validate().then(success => {
            if(!success) {
              this.alertMsg = 'Silakan periksa kembali kelengkapan data.'
              this.isShowAlert = true
            }
          })
        }
      }, 100)
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
          }
        })
        return data
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, 10)
        }]
      },
      handleMultiForm (type, itm) {
        if (type === 'add') {
          this.$store.dispatch('addFormHealth', itm)
        } else {
          this.$store.dispatch('removeFormHealth', itm)
        }
      },
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      handleOnSubmit (isDraft = false) {
        loadscreen.show()
        const formHealth = this.$store.state.formHealth
         const academicYearList = this.isDashboard ? this.$store.state.dashboard.dashAcademicYear : this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })

        let params = {
          health_form_url: {},
          health_related_file_url: {
            list: []
          }
        }
        Object.keys(formHealth).map(item => {
          if (typeof formHealth[item] !== 'string') {
            formHealth[item].map(itm => {
              let value = itm.value
              if (itm.type === 'file') {
                value = itm.path
              }
              if (itm.subfields) {
                if (params[itm.parent][itm.fields].length === 0) {
                  params[itm.parent][itm.fields] = []
                  params[itm.parent][itm.fields].push({
                    [itm.subfields]: value,
                    attr: itm.attr
                  })
                } else {
                  const idx = params[itm.parent][itm.fields].findIndex(obj => obj.attr === itm.attr)
                  if (idx !== -1) {
                    params[itm.parent][itm.fields].map(obj => {
                      if (obj.attr === itm.attr) {
                        obj[itm.subfields] = value
                      }
                    })
                  }
                  else {
                    params[itm.parent][itm.fields].push({
                      [itm.subfields]: value,
                      attr: itm.attr
                    })
                  }
                }
              } else {
                params[itm.parent][itm.fields] = value
              }
            })
          }
        })
        params.entry_form_id = this.formId
        params.academic_year_id = academicYearId
        params.health_form_url = get(params.health_form_url, 'health_form_url', '')
        // params.health_related_file_url = get(params.health_related_file_url, 'list', [])
        params.health_related_file_url = []
        let apiUrl = API_FORM_HEALTH
        if (this.isDashboard) {
          apiUrl = API_DASH_HEALTH_FORM
        }
        
        if (isDraft) {
          params.is_save_to_draft = true
        }

        request(apiUrl, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      downloadFile () {
        window.open(this.healthUrl, '_blank')
      },
      handleBack () {
        this.$emit('handleBack', true)
      },
      handleInput (form) {
        // console.log(form)
      }
    }
  }
</script>

<style lang="scss" scoped>
.wrapper-form-health {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
  &-body {
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>