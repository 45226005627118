<template>
  <div>
    <v-alert
      v-if="!isDisabledSave && !isHideButton"
      outlined
      type="warning"
      prominent
      border="left"
      class="mt-4"
    >
      Silakan pilih SIMPAN jika Anda belum yakin dengan data yang Anda masukan. Apabila semua data yang diperlukan sudah sesuai, silakan pilih KIRIM.
    </v-alert>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer ref="form">
      <v-form id="form-couple-agreement" class="couple-agreement" @submit.prevent="submitForm">
        <p class="couple-agreement-body mb-2 mt-4">Saya yang bertanda-tangan di bawah ini:</p>
        <template v-for="(itm, index) in getCoupleAgreementData">
          <v-row no-gutters :key="`agree${index}`" class="general-form">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="general-form__col"
              v-for="(item, idx) in separateColoum(itm.list)"
              :key="`ed${idx}`"
            >
              <template
                v-for="form in item.list"
              >
                <registration-input 
                  :key="form.title" 
                  :form="form"
                />
              </template>
            </v-col>
          </v-row>
          <v-divider v-if="index !== 1" :key="`agreeDiv${index}`" class="mb-4" ></v-divider>
          <div v-if="index !== 1" :key="`agreeInfo${index}`">
            <p class="couple-agreement-body mb-2 mt-4">dengan ini menyatakan persetujuan dan dukungan penuh kepada suami saya untuk menempuh studi S.Th.di STT Amanat Agung.</p>
            <validation-provider
              v-slot="{ errors }"
              name="Persetujuan"
              rules="required"
            >
              <v-checkbox
                v-model="is_agree_term"
                :error-messages="errors"
                :disabled="isHideButton"
                :value="is_agree_term"
                label="Pernyataan ini saya berikan secara sadar dan sukarela."
              ></v-checkbox>
            </validation-provider>
          </div>
        </template>
        <div v-if="!isHideButton" class="d-flex justify-end">
          <v-btn 
            v-if="!isDisabledSave"
            color="primary"
            :min-width="120"
            outlined
            class="mr-2"
            @click="handleSubmit(true)"
            large
          >
            SIMPAN
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-couple-agreement"
            :disabled="!is_agree_term"
            large
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-info ref="dialogInfoInvalid" :info="infoInvalid"/>
    <dialog-confirm ref="dialogConfirmSubmitCouple" :info="info" :action="handleSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, { getConfig } from '@/utils/request'
import { validateForm } from '@/utils/common/validateForm'

// Constants
import {
  API_FORM_COUPLE_AGREEMENT,
  API_DASH_COUPLE_AGREEMENT
} from '@/constants/apis'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import {
  formValidateCouple
} from '@/constants/formValidate'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '../common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
  export default {
    data () {
      return {
        activeStep: 1,
        is_agree_term: false,
        isShowAlert: false,
        alertMsg: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
        infoInvalid: {
          title: 'dialog.invalid',
          desc: 'dialog.invalid.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      RegistrationInput,
      DialogConfirm,
      DialogInfo,
    },
    props: {
      formId: {
        type: String,
        required: true
      }
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      isStudent () {
        const config = getConfig()
        return config.role === 'mahasiswa'
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      getCoupleAgreementData () {
        const coupleAgreementData = get(this.$store.state, 'formCoupleAgreement.couple_agreement_form', [])
        return this.getData(coupleAgreementData)
      },
      coupleAgreementForm () {
        return get(this.$store.state, 'couple_agreement_form', {})
      },
      isHideButton () {
        return (this.registrationStatus !== INIT && this.isStudent) || ([INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1 && this.isDashboard)
      },
      isDisabledSave () {
        const isCoupleForm = get(this.$store.state,'completionForm.is_couple_agreement_form', false)
        return !this.isStudent || isCoupleForm
      },
    },
    created () {
      this.$store.dispatch('getCoupleAgreement')
    },
    mounted () {
      extend('required', required)
      this.is_agree_term = get(this.coupleAgreementForm, 'is_agree_term', false)
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
          }
        })
        return data
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, 10)
        }]
      },
      handleBack () {
        this.$emit('handleBack', true)
      },
      submitForm () {
        this.$refs.form.validate().then(success => {
          if (!success) {
            const errors = this.$refs.form.errors
            this.activeStep = validateForm(formValidateCouple, errors)
            this.$refs.dialogInfoInvalid && this.$refs.dialogInfoInvalid.show()
          } else {
            // success
            this.$refs.dialogConfirmSubmitCouple && this.$refs.dialogConfirmSubmitCouple.show()
          }
        })
      },
      handleSubmit (isDraft = false) {
        loadscreen.show()
        const couple_agreement_form = this.$store.state.formCoupleAgreement.couple_agreement_form
        const academicYearList = this.isDashboard ? this.$store.state.dashboard.dashAcademicYear : this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        let params = {
          academic_year_id: academicYearId,
          entry_form_id: this.formId,
          is_agree_term: this.is_agree_term
        }
        Object.keys(couple_agreement_form).map(item => {
          let value = couple_agreement_form[item].value
          if (couple_agreement_form[item].type === 'date' || couple_agreement_form[item].type === 'year') {
            value = couple_agreement_form[item].value ? Date.parse(new Date(couple_agreement_form[item].value))/1000 : 0
          }
          if (couple_agreement_form[item].type === 'file') {
            value = couple_agreement_form[item].path
          }
          params[couple_agreement_form[item].fields] = value
        })
        
        let apiUrl = API_FORM_COUPLE_AGREEMENT
        if (this.isDashboard) {
          apiUrl = API_DASH_COUPLE_AGREEMENT
        }
        if (isDraft) {
          params.is_save_to_draft = true
        }
        request(apiUrl, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
.couple-agreement {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
  &-body {
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>